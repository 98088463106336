import { ofetch } from 'ofetch'

import { computed, onMounted, ref, watch } from 'vue'

import { useSessionStorage, watchOnce } from '@vueuse/core'

import { type RequestReturn } from '@shopify/shopify-api'

import { type Product, type ProductVariant } from '@/types'

import { type ShopifyGraphQLResponse } from '@/types/shopify'

import { 
  type Cart, 
  type CartCreate, 
  type CartById, 
  type CartLine, 
  type CartLineItem, 
  type CartLinesAdd, 
  type CartLinesRemove, 
  type CartLinesUpdate 
} from '@/types/cart'

import { type CartStoreItem, useCartStore } from '../useCartStore'

/**
 * 
 * reactive useCart()
 * 
 * @returns the cart, the cart identifyer and useful cart add and remove methods
 * @notes The cart is highly coupled with the cartStore composable.
 * 
 */
export const useCart = () => {
  const cartStore = useCartStore()

  const cart = ref<undefined | Cart>()

  const cartItems = useSessionStorage("cart-items", [] as CartStoreItem[])

  watchOnce(cartItems, () => {
    cartStore.syncCartItemsFromLocalStorage(cartItems.value)
  }, {
    immediate: true
  })

  const cartGID = useSessionStorage<string | undefined>("cart-gid", undefined)

  const cartId = computed<string>(() => {
    return cartGID.value?.split("gid://shopify/Cart/").pop() || ''
  })

  const createCart = async () => {
    const { body } = await ofetch<RequestReturn<ShopifyGraphQLResponse<CartCreate<Cart>>>>('/api/cart', {
      method: 'POST',
      cache: 'no-cache'
    })

    cart.value = body.data.cartCreate.cart
  }

  const addLineToCart = async (cartId: string, line: Partial<CartLineItem>) => {
    const { body } = await ofetch<RequestReturn<ShopifyGraphQLResponse<CartLinesAdd<Cart>>>>(
      `/api/cart/lines/add/${cartId}`,
      {
        method: 'PUT',
        body: JSON.stringify(line),
        cache: 'no-cache'
      }
    )

    cart.value = body.data.cartLinesAdd.cart
  }

  const removeLineFromCart = async (cartId: string, lineId: string) => {
    const { body } = await ofetch<RequestReturn<ShopifyGraphQLResponse<CartLinesRemove<Cart>>>>(
      `/api/cart/lines/remove/${cartId}`,
      { 
        method: 'PUT', 
        body: JSON.stringify({
          id: lineId
        }),
        cache: 'no-cache'
      }
    )

    cart.value = body.data.cartLinesRemove.cart
  }

  const adjustLineQuantityInCart = async (cartId: string, line: Partial<CartLineItem>) => {
    const { body } = await ofetch<RequestReturn<ShopifyGraphQLResponse<CartLinesUpdate<Cart>>>>(`/api/cart/lines/update/${cartId}`, {
      method: 'PUT',
      body: JSON.stringify(line),
      cache: 'no-cache'
    })

    cart.value = body.data.cartLinesUpdate.cart
  }

  const fetchCartById = async (cartId: string) => {
    const { body } = await ofetch<RequestReturn<ShopifyGraphQLResponse<CartById<Cart>>>>(
      `/api/cart/${cartId}`, 
      {
        cache: 'no-cache'
      }
    )

    if (!body.data) {
      cartGID.value = null
    }

    if (!body.data.cart) {
      cartGID.value = null
    }

    cart.value = body.data.cart
  }

  watch(cart, () => {
    if (cart.value) {
      // Persist the cart to the store:
      cartStore.syncCart(cart.value)

      cartGID.value = cart.value.id

      cartStore.setCartTotal(parseInt(cart.value.cost.totalAmount.amount))
    }
  }, {
    immediate: true,
    deep: true
  })

  const findLineItemWithProductVariantId = (lines: CartLine[], productVariant: ProductVariant): CartLine | undefined => {
    return lines.find(line => line.merchandise.id === productVariant.id)
  }

  const addProductToCart = async (product: Product, productVariant: ProductVariant, quantity: number) => {
    // Add The Product To The Currently Active Cart Session:
    if (cartId.value) {
      const line: Partial<CartLineItem> = {
        merchandiseId: productVariant.id,
        quantity: quantity,
        sellingPlanId: ''
      }

      await addLineToCart(cartId.value, line)
    }

    const subtotal = cart.value?.cost.totalAmount.amount || "0"

    // Set the Cart total:
    cartStore.setCartTotal(parseInt(subtotal))

    // Add The Product To The Store:
    cartStore.addProductToCart(productVariant.id, product, productVariant, quantity)

    cartItems.value = cartStore.getAllItems
  }

  const removeProductFromCart = async (product: Product, productVariant: ProductVariant, quantity: number) => {
    // Remove The Product From The Currently Active Cart Session:
    if (cartId.value) {
      const lineItems = cart.value?.lines.edges.map((edge) => edge.node) || []

      const lineItem = findLineItemWithProductVariantId(lineItems, productVariant)

      if (lineItem) {
        // Remove The Line Item From The Cart:
        await removeLineFromCart(cartId.value, lineItem.id)
      }
    }

    const subtotal = cart.value?.cost.totalAmount.amount || "0"

    // Set the Cart total:
    cartStore.setCartTotal(parseInt(subtotal))

    // Remove The Product From The Store:
    cartStore.removeProductFromCart(productVariant.id, product, productVariant, quantity)

    cartItems.value = cartStore.getAllItems
  }

  const updateProductInCart = async (product: Product, productVariant: ProductVariant, quantity: number) => {
    // Update The Product In The Currently Active Cart Session:
    if (cartId.value) {
      const lineItems = cart.value?.lines.edges.map((edge) => edge.node) || []

      const lineItem = findLineItemWithProductVariantId(lineItems, productVariant)

      if (!lineItem) {
        return
      }

      const line: Partial<CartLineItem> = {
        id: lineItem.id,
        merchandiseId: productVariant.id,
        quantity: quantity,
        sellingPlanId: ''
      }

      // Update The Line Item From The Cart:
      await adjustLineQuantityInCart(cartId.value, line)
    }

    const subtotal = cart.value?.cost.totalAmount.amount || "0"

    // Set the Cart total:
    cartStore.setCartTotal(parseInt(subtotal))

    // Update The Product In The Store:
    cartStore.updateProductInCart(productVariant.id, productVariant, quantity)

    cartItems.value = cartStore.getAllItems
  }

  const resetCart = async () => {
    // Reset the cart in the store:
    cartStore.resetCart()
    // Reset the cart line items to blank array:
    cartItems.value = []
    // Set the cart GID to undefined to flush from session storage:
    cartGID.value = undefined
    // Once the existing cart has been reset, we can create a new one:
    createCart()
  }

  onMounted(() => {
    // Check if there is a active cart Id, if true, fetch the cart:
    if (cartGID.value && cartId.value) {
      // Get the cart from the API:
      return fetchCartById(cartId.value)
    } else {
      // Create the cart from the API:
      return createCart()
    }
  })

  return {
    cart,
    cartId,
    addProductToCart,
    removeProductFromCart,
    updateProductInCart,
    resetCart,
    cartStore,
    cartTotal: computed(() => cartStore.total)
  }
}